import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
} from "react-router-dom";
import "./styles/normalize.css";

import Login from "./components/Login";
import { setRawCookie } from "react-cookies";
import Entrance from "./components/Entrance";
import Popup from "./components/common/Popup";

class App extends Component {
  constructor(props) {
    super(props);
    this.setRem = this.setRem.bind(this);
  }

  componentDidMount() {
    window.onresize = this.setRem;
  }
  // 根据rem修改视口高度
  setRem() {
    let w = window.innerWidth;
    let h = window.innerHeight;
    let fontSize = 16;
    if (w >= 1400) {
      fontSize = 16;
    } else if (w < 1400 && w > 1024) {
      fontSize = 13;
    } else if (w <= 1024) {
      fontSize = 9;
    } else {
      fontSize = (w / 960) * 8;
    }
    document.documentElement.style.fontSize = fontSize + "px";
  }

  render() {
    return (
      <HashRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route path="/Login" component={Login}></Route>
            <Route path="/" component={Entrance}></Route>
          </Switch>
        </React.Fragment>
      </HashRouter>
    );
  }
}

export default App;
