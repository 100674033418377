import React, { Component } from "react";
import "../styles/table.scss";
import HeaderBar from "./common/HeaderBar";
import AddMessage from "../icons/AddMessage";
import DownIcon from "../icons/DownIcon";
import CopyIcon from "../icons/CopyIcon";
import MoreIcon from "../icons/MoreIcon";
import XiaZai from "../icons/XiaZai";

import Pagination from "@material-ui/lab/Pagination";
import FooterBar from "./common/FooterBar";
import FlagIcon from "./common/FlagIcon";
import Loading from "./common/Loading";
import cookie from "react-cookies";
import * as GetAccountsAPI from "../apis/GetAccountsAPI";
import { setAPIAuthorizationToken } from "../apis/API";
import * as QuestionAPI from "../apis/QuestionAPI";
import * as MaterialAPI from "../apis/MaterialAPI";
import LocalStorageUtil from "../utils/LocalstorageUtil";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: "",
      loadingShow: false,
      StateShow: false,
      count: 0,
      start: 0,
      length: 8,
      page: 1,
      stat: "",
      search: "",
      StatusList: [
        {
          title: "All",
          value: "",
        },
        {
          title: "Wait Register",
          value: 0,
        },
        {
          title: "Error Information",
          value: 1,
        },
        {
          title: "Wait Trigger",
          value: 2,
        },
        {
          title: "Already Triggered",
          value: 3,
        },
        {
          title: "Selife Success",
          value: 4,
        },
        {
          title: "Wrong Information",
          value: 6,
        },
        {
          title: "Account Die",
          value: 7,
        },
      ],
      table: [],
    };
    this.clickSelectShow = this.clickSelectShow.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.clickRouteEdit = this.clickRouteEdit.bind(this);
    this.clickCopyAndPaste = this.clickCopyAndPaste.bind(this);
    this.clickOperateShow = this.clickOperateShow.bind(this);
    this.getQuestion = this.getQuestion.bind(this);
    this.searchData = this.searchData.bind(this);
    this.receiveSearchText = this.receiveSearchText.bind(this);
    this.getMaterial = this.getMaterial.bind(this);
  }

  componentDidMount() {
    // 取cookie
    const param = cookie.load("token");
    if (param) {
      this.setState({ userID: param.group_id });
      setAPIAuthorizationToken(param.token);

      // table
      const { start, length, stat, search } = this.state;
      this.getTable(start, length, stat, search);
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
    this.props.onRef && this.props.onRef(this);
  }

  // 获取table
  getTable(start, length, stat, search) {
    this.setState({ loadingShow: true });
    GetAccountsAPI.GetAccounts(start, length, stat, search).then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var status = res.status;
      if (status === 200) {
        var total = res.data.recordsTotal;
        total = Math.ceil(total / 8);
        console.log(total);
        this.setState({ count: total });
        var table = res.data.data;
        console.log(table);
        const { StatusList } = this.state;
        table &&
          table.map((item, index) => {
            StatusList.map((status, j) => {
              if (status.value === item.state) {
                item.stateTitle = status.title;
                console.log(item.state);
              }
            });
            if (item.state === 0) {
              item.stateClass = "pending";
            } else if (item.state === 4) {
              item.stateClass = "suc";
            } else if (item.state === 2) {
              item.stateClass = "acc";
            } else {
              item.stateClass = "err";
            }
          });
        this.setState({ table: table });
      }
    });
  }

  handlePageChange(e, page) {
    this.setState({ page: page }, () => {
      let { start } = this.state;
      start = (page - 1) * 8;
      this.setState({ start: start }, () => {
        const { start, length, stat, search } = this.state;
        this.getTable(start, length, stat, search);
      });
    });
  }

  clickSelectShow() {
    this.setState({ StateShow: !this.state.StateShow });
  }

  clickRouteEdit(id) {
    const { history } = this.props;
    console.log(id);
    console.log(typeof id);
    if (id !== undefined) {
      history.push(`/Edit?id=${id}`);
    } else {
      history.push("/Edit");
    }
  }

  clickCopyAndPaste(text) {
    let copyText = text;
    navigator.clipboard.writeText(copyText);
    window.dispatchEvent(
      new CustomEvent("showAlert", {
        detail: "copy success",
      })
    );
  }
  clickOperateShow(index) {
    const { table } = this.state;
    table[index].operateShow = !table[index].operateShow;
    this.setState({ table: table });
  }

  // question
  getQuestion(id, type) {
    QuestionAPI.postQuestion(id, type).then((res) => {
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var msg = res.data.msg;
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      }
    });
  }
  // material
  getMaterial(id) {
    MaterialAPI.getMaterial(id).then((res) => {
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var msg = res.data.msg;
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      }
    });
  }

  searchRef = React.createRef();
  // 搜索
  searchData(searchText) {
    this.setState({ search: searchText }, () => {
      const { start, length, stat } = this.state;
      this.getTable(0, length, stat, searchText);
    });
  }

  receiveSearchText(searchText) {
    this.setState({ search: searchText });
  }

  render() {
    const { StateShow, table, page, count, StatusList, userID } = this.state;
    const { ref } = this.props;
    return (
      <React.Fragment>
        <Loading loadingShow={this.state.loadingShow} />
        <HeaderBar
          {...this.props}
          ref={this.searchRef}
          searchCallback={this.searchData}
          updateParentSearchText={this.receiveSearchText}
        />
        <div className="content-body">
          <div className="table-contain">
            <div className="table-header">
              <div className="select-box" onClick={this.clickSelectShow}>
                <span>Status </span>
                <DownIcon size={13} />
                <ul className={`select-body ${StateShow ? "select-show" : ""}`}>
                  {StatusList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => this.getTable(0, 8, item.value, "")}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                  {/* <li>All</li>
                  <li>Pending Registration</li>
                  <li>Error Message</li>
                  <li>Wait To Trigger</li>
                  <li>Already Triggered</li>
                  <li>Face Recognition Success</li>
                  <li>Maintenance Inspection</li>
                  <li>Account Failure</li> */}
                </ul>
              </div>
              <div className="right">
                {/* <div className="download">
                  <XiaZai size={35} color="#575757" />
                </div> */}
                {userID === 3 && (
                  <div
                    className="add-btn"
                    onClick={() => this.clickRouteEdit()}
                  >
                    <AddMessage
                      size={30}
                      color="#575757"
                      className="edit-icon"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="form-body">
              <div className="form-header">
                <div className="time">Time</div>
                <div className="email">Email</div>
                <div className="state">Status</div>
                {userID === 2 && <div className="vps">Staff</div>}
                <div className="register">Register</div>
                <div className="country">Country</div>
                <div className="note">Notes</div>
                <div className="note2">Notes2</div>
                <div className="sk">SK</div>
                <div className="pk">PK</div>
                <div className="operation">Operation</div>
              </div>
              <div className="form-contain">
                {table &&
                  table.map((item, index) => {
                    return (
                      <ul className="list" key={index}>
                        <li className="time">{item.date}</li>
                        <li
                          className="email"
                          onClick={() => {
                            this.clickCopyAndPaste(item.email);
                          }}
                        >
                          {item.email}
                        </li>
                        <li className="status">
                          <p className={item.stateClass}>{item.stateTitle}</p>
                        </li>
                        {userID === 2 && (
                          <li className="vps">{item.staff_name}</li>
                        )}
                        <li className="register">
                          {item.pass === 0 && <p className="quick">quickly</p>}
                          {item.pass === 1 && <p className="slow">slowly</p>}
                        </li>
                        <li
                          className="country"
                          onClick={() => {
                            this.clickCopyAndPaste(item.country);
                          }}
                        >
                          {/* <FlagIcon name={item.country} /> */}
                          {item.country !== null ? (
                            <img
                              src={`./static/media/${item.country}.png`}
                              alt="country_flag"
                            />
                            // <div className="" style={{backgroundImage: `url(./static/media/${item.country}.png)`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}></div>
                          ) : (
                            <div className="img"></div>
                          )}
                          {/* {item.country === "null" && <img />} */}
                          {/* {item.country} */}
                        </li>
                        <li
                          className="note"
                          onClick={() => {
                            this.clickCopyAndPaste(item.remarks);
                          }}
                        >
                          {item.remarks}
                        </li>
                        <li
                          className="note2"
                          onClick={() => {
                            this.clickCopyAndPaste(item.remarks1);
                          }}
                        >
                          {item.remarks1}
                        </li>
                        <li
                          className="sk"
                          onClick={() => {
                            this.clickCopyAndPaste(item.sk);
                          }}
                        >
                          <div className="sk-btn">
                            <CopyIcon size={20} color="#339c94" />
                          </div>
                        </li>
                        <li
                          className="pk"
                          onClick={() => {
                            this.clickCopyAndPaste(item.pk);
                          }}
                        >
                          <div className="pk-btn">
                            <CopyIcon size={20} color="#339c94" />
                          </div>
                        </li>
                        <li
                          className="operation"
                          onClick={() => this.clickOperateShow(index)}
                        >
                          <div className="action-btn">
                            <MoreIcon size={24} color="#ffa755" />
                          </div>
                          {userID === 3 && (
                            <React.Fragment>
                              <ul
                                className={`operation-ul ${
                                  item.operateShow ? "operate-show" : ""
                                }`}
                              >
                                <li
                                  onClick={() => this.clickRouteEdit(item.id)}
                                >
                                  Edit
                                </li>

                                <li>
                                  {item.images.length > 0
                                    ? "ID photo (Haven uploaded)"
                                    : "ID photo (Haven't uploaded)"}
                                </li>

                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.websiteUrl);
                                  }}
                                >
                                  Copy website
                                </li>
                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.logemail);
                                  }}
                                >
                                  Copy email site
                                </li>
                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.emailpsw);
                                  }}
                                >
                                  Copy email password
                                </li>
                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.faceurl);
                                  }}
                                >
                                  Copy face verification link
                                </li>
                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.verifyurl);
                                  }}
                                >
                                  Copy email verification link
                                </li>
                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.stripepsw);
                                  }}
                                >
                                  Copy stripe password
                                </li>
                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.stripe2fa);
                                  }}
                                >
                                  Copy 2FA
                                </li>
                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.stripecode);
                                  }}
                                >
                                  Copy back code
                                </li>
                                <li
                                  onClick={() => {
                                    this.clickCopyAndPaste(item.bankaccount);
                                  }}
                                >
                                  Copy bank
                                </li>
                              </ul>
                            </React.Fragment>
                          )}

                          {userID === 2 && (
                            <React.Fragment>
                              {item.state === 0 && (
                                <ul
                                  className={`operation-ul ${
                                    item.operateShow ? "operate-show" : ""
                                  }`}
                                >
                                  <li
                                    onClick={() => this.clickRouteEdit(item.id)}
                                  >
                                    Edit
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.websiteUrl);
                                    }}
                                  >
                                    Copy website
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.logemail);
                                    }}
                                  >
                                    Copy email site
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.emailpsw);
                                    }}
                                  >
                                    Copy email password
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.faceurl);
                                    }}
                                  >
                                    Copy face verification link
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.verifyurl);
                                    }}
                                  >
                                    Copy email verification link
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripepsw);
                                    }}
                                  >
                                    Copy stripe password
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripe2fa);
                                    }}
                                  >
                                    Copy 2FA
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripecode);
                                    }}
                                  >
                                    Copy back code
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.bankaccount);
                                    }}
                                  >
                                    Copy bank
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 0)}
                                  >
                                    Website can't open
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 1)}
                                  >
                                    I need email login code
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 2)}
                                  >
                                    Email password error
                                  </li>
                                  {/* <li
                                    onClick={() => this.getMaterial(item.id)}
                                  >
                                    Data number
                                  </li> */}
                                </ul>
                              )}
                              {item.state === 1 && (
                                <ul
                                  className={`operation-ul ${
                                    item.operateShow ? "operate-show" : ""
                                  }`}
                                >
                                  <li
                                    onClick={() => this.clickRouteEdit(item.id)}
                                  >
                                    Edit
                                  </li>
                                </ul>
                              )}
                              {item.state === 2 && (
                                <ul
                                  className={`operation-ul ${
                                    item.operateShow ? "operate-show" : ""
                                  }`}
                                >
                                  <li
                                    onClick={() => this.clickRouteEdit(item.id)}
                                  >
                                    Edit
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.websiteUrl);
                                    }}
                                  >
                                    Copy website
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.logemail);
                                    }}
                                  >
                                    Copy email site
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.emailpsw);
                                    }}
                                  >
                                    Copy email password
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.faceurl);
                                    }}
                                  >
                                    Copy face verification link
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.verifyurl);
                                    }}
                                  >
                                    Copy email verification link
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripepsw);
                                    }}
                                  >
                                    Copy stripe password
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripe2fa);
                                    }}
                                  >
                                    Copy 2FA
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripecode);
                                    }}
                                  >
                                    Copy back code
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.bankaccount);
                                    }}
                                  >
                                    Copy bank
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 0)}
                                  >
                                    Website can't open
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 1)}
                                  >
                                    I need email login code
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 2)}
                                  >
                                    Email password error
                                  </li>
                                </ul>
                              )}
                              {item.state === 3 && (
                                <ul
                                  className={`operation-ul ${
                                    item.operateShow ? "operate-show" : ""
                                  }`}
                                >
                                  <li
                                    onClick={() => this.clickRouteEdit(item.id)}
                                  >
                                    Edit
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.websiteUrl);
                                    }}
                                  >
                                    Copy website
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.logemail);
                                    }}
                                  >
                                    Copy email site
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.emailpsw);
                                    }}
                                  >
                                    Copy email password
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.faceurl);
                                    }}
                                  >
                                    Copy face verification link
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.verifyurl);
                                    }}
                                  >
                                    Copy email verification link
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripepsw);
                                    }}
                                  >
                                    Copy stripe password
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripe2fa);
                                    }}
                                  >
                                    Copy 2FA
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.stripecode);
                                    }}
                                  >
                                    Copy back code
                                  </li>
                                  <li
                                    onClick={() => {
                                      this.clickCopyAndPaste(item.bankaccount);
                                    }}
                                  >
                                    Copy bank
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 0)}
                                  >
                                    Website can't open
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 1)}
                                  >
                                    I need email login code
                                  </li>
                                  <li
                                    onClick={() => this.getQuestion(item.id, 2)}
                                  >
                                    Email password error
                                  </li>
                                  {/* <li onClick={() => this.getMaterial(item.id)}>
                                    Data number
                                  </li> */}
                                </ul>
                              )}
                              {item.state === 4 && (
                                <ul
                                  className={`operation-ul ${
                                    item.operateShow ? "operate-show" : ""
                                  }`}
                                >
                                  <li
                                    onClick={() => this.clickRouteEdit(item.id)}
                                  >
                                    Edit
                                  </li>
                                  {/* 
                                  <li
                                    onClick={() => this.getQuestion(item.id, 3)}
                                  >
                                    Website Verification
                                  </li> */}
                                </ul>
                              )}
                              {item.state === 6 && (
                                <ul
                                  className={`operation-ul ${
                                    item.operateShow ? "operate-show" : ""
                                  }`}
                                >
                                  <li
                                    onClick={() => this.clickRouteEdit(item.id)}
                                  >
                                    Edit
                                  </li>
                                </ul>
                              )}
                              {item.state === 7 && (
                                <ul
                                  className={`operation-ul ${
                                    item.operateShow ? "operate-show" : ""
                                  }`}
                                >
                                  <li
                                    onClick={() => this.clickRouteEdit(item.id)}
                                  >
                                    Edit
                                  </li>
                                </ul>
                              )}
                              {item.state === "" ||
                                (item.state === 5 && (
                                  <ul
                                    className={`operation-ul ${
                                      item.operateShow ? "operate-show" : ""
                                    }`}
                                  >
                                    <li
                                      onClick={() =>
                                        this.clickRouteEdit(item.id)
                                      }
                                    >
                                      Edit
                                    </li>
                                  </ul>
                                ))}
                            </React.Fragment>
                          )}
                        </li>
                      </ul>
                    );
                  })}
              </div>
            </div>
            <div className="pagination">
              <Pagination
                count={count}
                page={page}
                onChange={this.handlePageChange}
                variant="outlined"
                color="standard"
              />
            </div>
            <FooterBar {...this.props} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Table;
