import axios from "axios";

// Creates api instance to be used accross the project
// and set appropriate default values
//
// There is a bug in which axios leaks instance headers to the global
// axios object. There is a workaround which is using a deep object in the
// instance initialization.
// https://github.com/axios/axios/issues/1664

let url ="https://test.nigga.tools";
  // window.location.host.indexOf("localhost") < 0
  //   ? window.location.protocol + "//" + window.location.host
  //   : "https://test.stripevip.com";
const api = axios.create({
  baseURL: url,
  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
  withCredentials: true,
});

// Create aliases
api.create = api.post;
api.update = api.put;

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

// Sets authorization header. Only to be used in log in
export function setAPIAuthorizationToken(accessToken) {
  api.defaults.headers.common["token"] = `${accessToken}`;
}

// Unsets authorization header. Only to be used in log out
export function unsetAPIAuthorizationToken() {
  delete api.defaults.headers.common["Authorization"];
}

// Sets base api url. Only to be used in dev when changing environment
export function setAPIBaseUrl(baseURL) {
  api.defaults.baseURL = baseURL;
}

export default api;
