import React, { Component } from "react";
import "../styles/home.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import TotalIcon from "../icons/TotalIcon";
import SuccessIcon from "../icons/SuccessIcon";
import ErrorIcon from "../icons/ErrorIcon";
import circle from "../image/circle.png";
import ChangeIcon from "../icons/ChangeIcon";
import DownAllowIcon from "../icons/DownAllowIcon";
import BagIcon from "../icons/BagIcon";

import Img from "../image/pic1.jpg";

import UploadIcon from "../icons/UploadIcon";
import DownloadIcon from "../icons/DownloadIcon";

import ChengIcon from "../icons/ChengIcon";
import RecodeIcon from "../icons/RecodeIcon";
import NoContent from "../icons/NoContent";

import * as echarts from "echarts/core";
import ReactECharts from "echarts-for-react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { BarChart, PieChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";

import Loading from "../components/common/Loading";
import cookie from "react-cookies";

import * as GetCountryAPI from "../apis/GetCountryAPI";
import * as GetIndexAPI from "../apis/GetIndexAPI";
import * as GetMoneyAPI from "../apis/GetMoneyAPI";
import { setAPIAuthorizationToken } from "../apis/API";
import CloseIcon from "../icons/CloseIcon";

import * as WithDrawAPI from "../apis/WithDrawAPI";
import * as RechargeAPI from "../apis/RechargeAPI";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rechargeShow: false,
      UserID: "",
      username: "",
      avatar: "",
      change: 0,
      tabName: "Weekly",
      sliderValue: 20000,
      loadingShow: false,
      MoneyData: [],
      CountryData: [],
      // 成功/失败
      pieOption: {
        // legend: {
        //   top: "5%",
        //   left: "center",
        // },
        series: [
          {
            top: "0%",
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 1048, name: "Success", itemStyle: { color: "#68e365" } },
              { value: 735, name: "Fail", itemStyle: { color: "#f72b50" } },
              { value: 735, name: "Other", itemStyle: { color: "#5470c6" } },
            ],
          },
        ],
      },
      // 余额变动
      DataOption: {
        grid: {
          top: "20%",
        },
        legend: {
          top: "2%",
          left: "right",
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Income",
            type: "line",
            data: [],
            label: {
              show: true,
              position: "top",
              valueAnimation: true,
            },
            itemStyle: { color: "#68e365" },
          },
        ],
      },
      // 每日数据
      DailyDate: {
        grid: {
          top: "20%",
        },
        legend: {
          top: "2%",
          left: "right",
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Daily Date",
            type: "line",
            data: [],
            label: {
              show: true,
              position: "top",
              valueAnimation: true,
            },
            itemStyle: { color: "#68e365" },
          },
        ],
      },

      // 每日成功率
      DailySuccessRate: {
        grid: {
          top: "20%",
        },
        legend: {
          top: "2%",
          left: "right",
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Error",
            type: "bar",
            data: [],
            label: {
              show: true,
              position: "top",
              valueAnimation: true,
            },
            itemStyle: { color: "#f72b50" },
          },
          {
            name: "Success",
            type: "bar",
            data: [],
            label: {
              show: true,
              position: "top",
              valueAnimation: true,
            },
            itemStyle: { color: "#68e365" },
          },
        ],
      },

      // 角色1
      walletMoney: "",
      totalNumber: "",
      todaySuccess: "",
      yesterdaySuccess: "",
      yesterdayError: "",
      walletAddress: "",
      walletMethod: "",
      Amount: "",
      Hash: "",
      SuccessNum: 0,
      ErrorNum: 0,

      // 角色2
      currentlyTotalNum: "",
      totalSuccessNum: "",
    };
    this.ClickChangeBtn = this.ClickChangeBtn.bind(this);
    this.getPieOption = this.getPieOption.bind(this);
    this.getLinerOption = this.getLinerOption.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.sliderHandleChange = this.sliderHandleChange.bind(this);
    this.getChargeOption = this.getChargeOption.bind(this);
    this.clickRechargeHidden = this.clickRechargeHidden.bind(this);
    this.clickRechargeShow = this.clickRechargeShow.bind(this);
    this.clickModel = this.clickModel.bind(this);
    this.changeRecharge = this.changeRecharge.bind(this);
    this.getMoney = this.getMoney.bind(this);
    this.getCountry = this.getCountry.bind(this);
    this.getWeeklyTotal = this.getWeeklyTotal.bind(this);
    this.getMonthlyTotal = this.getMonthlyTotal.bind(this);
    this.withDrawBtn = this.withDrawBtn.bind(this);
    this.recharge = this.recharge.bind(this);
  }

  componentDidMount() {
    const param = cookie.load("token");
    if (param) {
      // 判断角色
      this.setState({ UserID: param.group_id });
      this.setState({ username: param.username });
      this.setState({ avatar: param.avatar });
      setAPIAuthorizationToken(param.token);

      // 获取国家
      this.getCountry();
      // 获取money
      this.getMoney();
      // 获取index
      GetIndexAPI.getIndex().then((res) => {
        console.log(res);
        var code = res.data.code;
        if (code === 1) {
          var data = res.data.data;
          console.log(data);
          if (data) {
            // 钱包
            const balance = data.TodayMoney;
            console.log(balance);
            this.setState({ walletMoney: balance });
            this.setState({ totalNumber: data.Todaynums });
            this.setState({ todaySuccess: data.TodayNum });
            this.setState({ yesterdaySuccess: data.YesterdaySuccess });
            this.setState({ yesterdayError: data.YesterdayFail });
            this.setState({ walletAddress: data.address });
            this.setState({walletMethod:data.address})
            // 2
            this.setState({ currentlyTotalNum: data.TodaysaccountNum });
            this.setState({ totalSuccessNum: data.Todaynums });

            // 成功率
            const { pieOption } = this.state;
            let pieDate = pieOption.series[0].data;
            pieDate[0].value = data.accountSuccess;
            pieDate[1].value = data.accountError;
            pieDate[2].value = data.accountOther;
            this.setState({ pieOption: pieOption });

            // 余额变动
            let { DataOption } = this.state;
            let optionData = [].concat(DataOption.series[0].data);
            let xAxis = [].concat(DataOption.xAxis[0].data);
            let stackedBarChart = data.stackedBarChart;
            console.log(stackedBarChart);
            stackedBarChart.map((item, index) => {
              xAxis.push(item.x);
              optionData.push(parseFloat(item.a));
            });

            let newDataOption = Object.assign({}, DataOption);
            newDataOption.series[0].data = optionData;
            newDataOption.xAxis[0].data = xAxis;
            console.log(newDataOption);
            this.setState({ DataOption: newDataOption });

            // 每日数据
            let { DailyDate } = this.state;
            let YDaily = [].concat(DailyDate.series[0].data);
            let XDaily = [].concat(DailyDate.xAxis[0].data);
            let negativeValues = data.negativeValues;
            negativeValues.map((item, index) => {
              XDaily.push(item.x);
              YDaily.push(parseFloat(item.y));
            });
            let newDailyDate = Object.assign({}, DailyDate);
            newDailyDate.series[0].data = YDaily;
            newDailyDate.xAxis[0].data = XDaily;
            console.log("每日数据");
            console.log(newDailyDate);
            this.setState({ DailyDate: newDailyDate });

            // 每日成功率
            let { DailySuccessRate } = this.state;
            let YError = [].concat(DailySuccessRate.series[0].data);
            let YSuccess = [].concat(DailySuccessRate.series[1].data);
            let XData = [].concat(DailySuccessRate.xAxis[0].data);
            let morrisBarChart = data.morrisBarChart;
            morrisBarChart.map((item, index) => {
              YError.push(item.y);
              YSuccess.push(item.a);
              XData.push(item.x);
            });
            let newDailySuccessRate = Object.assign({}, DailySuccessRate);
            newDailySuccessRate.series[0].data = YError;
            newDailySuccessRate.series[1].data = YSuccess;
            newDailySuccessRate.xAxis[0].data = XData;
            this.setState({ DailySuccessRate: newDailySuccessRate });
          }

          this.getWeeklyTotal();
        }
      });
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
  }

  // getMoney
  getMoney() {
    // 获取money
    this.setState({ loadingShow: true });
    GetMoneyAPI.getMoney().then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var data = res.data.data;
        if (data) {
          console.log(data);
          data.map((item, index) => {
            item.createtime = new Date(
              parseInt(item.createtime) * 1000
            ).toLocaleString();
          });
          this.setState({ MoneyData: data });
        }
      }
    });
  }

  // 获取国家
  getCountry() {
    this.setState({ loadingShow: true });
    GetCountryAPI.getCountry().then((res) => {
      this.setState({ loadingShow: false });
      var code = res.data.code;
      if (code === 1) {
        var data = res.data.data;
        if (data) {
          this.setState({ CountryData: data });
        }
      }
    });
  }

  // 获取钱包
  ClickChangeBtn() {
    let { change } = this.state;
    change += 360;
    this.setState({ change: change });
    this.getMoney();
  }

  // 获取weekly
  getWeeklyTotal() {
    GetIndexAPI.getIndex().then((res) => {
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var data = res.data.data;
        console.log(data);
        if (data) {
          this.setState({ SuccessNum: data.Weeklys });
          this.setState({ ErrorNum: data.Weeklyf });
        }
      }
    });
  }
  // 获取monthly
  getMonthlyTotal() {
    GetIndexAPI.getIndex().then((res) => {
      console.log(res);
      var code = res.data.code;
      if (code === 1) {
        var data = res.data.data;
        console.log(data);
        if (data) {
          this.setState({ SuccessNum: data.Monthlys });
          this.setState({ ErrorNum: data.Monthlyf });
        }
      }
    });
  }

  getPieOption() {
    let { pieOption } = this.state;
    return pieOption;
  }

  getLinerOption() {
    let { linerOption } = this.state;
    return linerOption;
  }

  getChargeOption() {
    let { chargeOption } = this.state;
    return chargeOption;
  }

  tabChange(type) {
    this.setState({ tabName: type });
    switch (type) {
      case "Weekly":
        this.getWeeklyTotal();
        break;
      case "Monthly":
        this.getMonthlyTotal();
        break;
      default:
        break;
    }
  }

  sliderHandleChange(e, newValue) {
    this.setState({ sliderValue: newValue });
  }

  clickRechargeHidden() {
    this.setState({ rechargeShow: false });
  }
  clickRechargeShow() {
    this.setState({ rechargeShow: true });
  }

  clickModel(e) {
    e && e.stopPropagation();
  }

  withDrawBtn() {
    const { walletAddress, sliderValue } = this.state;
    WithDrawAPI.withDraw(walletAddress, sliderValue).then((res) => {
      console.log(res);
      var msg = res.data.msg;
      alert(msg);
    });
  }

  recharge() {
    const { Hash } = this.state;
    RechargeAPI.recharge(Hash).then((res) => {
      this.setState({ Hash: "" });
      console.log(res);
      var msg = res.data.msg;
      alert(msg);

      this.clickRechargeHidden();
      console.log("0000000000");
    });
  }

  changeRecharge(type, e) {
    switch (type) {
      case "Wallet":
        this.setState({ walletAddress: e.target.value });
        break;
      case "Method":
        this.setState({ walletMethod: e.target.value });
        break;
      case "Amount":
        this.setState({ sliderValue: e.target.value });
      case "Hash":
        this.setState({ Hash: e.target.value });
      default:
        break;
    }
  }

  render() {
    const {
      change,
      tabName,
      sliderValue,
      walletMoney,
      CountryData,
      MoneyData,
      avatar,
      username,
      UserID,
      rechargeShow,
      todaySuccess,
      totalNumber,
      yesterdayError,
      yesterdaySuccess,
      walletAddress,
      walletMethod,
      pieOption,
      SuccessNum,
      ErrorNum,
      currentlyTotalNum,
      totalSuccessNum,
    } = this.state;
    return (
      <React.Fragment>
        <Loading loadingShow={this.state.loadingShow} />
        <HeaderBar {...this.props} />
        {/* 充值弹窗 */}
        <div
          className={`charge-box ${rechargeShow ? "recharge-show" : ""}`}
          onClick={this.clickRechargeHidden}
        >
          <div className="row">
            <div className="box" onClick={this.clickModel}>
              <div className="head">
                {UserID === 1 && "WITHDRAW"}
                {UserID === 2 && "WITHDRAW"}
                {UserID === 3 && "RECHARGE"}
                <CloseIcon
                  size={24}
                  color="#fff"
                  onClick={this.clickRechargeHidden}
                />
              </div>
              {UserID === 1 && (
                <div className="input-row">
                  <p>Wallet Method :</p>
                  <input
                    type="text"
                    value={walletMethod || ''}
                    onChange={(e) => this.changeRecharge("Method", e)}
                  />
                </div>
              )}
              {UserID === 2 && (
                <div className="input-row">
                  <p>Wallet Method :</p>
                  <input
                    type="text"
                    value={walletMethod || ''} 
                    onChange={(e) => this.changeRecharge("Method", e)}
                  />
                </div>
              )}
              {UserID === 3 && (
                <div className="input-row">
                  <p>Wallet Address :</p>
                  <input
                    type="text"
                    value={walletAddress || ""}
                    onChange={(e) => this.changeRecharge("Wallet", e)}
                  />
                </div>
              )}

              {UserID === 1 && (
                <div className="input-row">
                  <p>Amount :</p>
                  <input
                    type="text"
                    value={sliderValue}
                    onChange={(e) => this.changeRecharge("Amount", e)}
                  />
                </div>
              )}
              {UserID === 2 && (
                <div className="input-row">
                  <p>Amount :</p>
                  <input
                    type="text"
                    value={sliderValue}
                    onChange={(e) => this.changeRecharge("Amount", e)}
                  />
                </div>
              )}
              {UserID === 3 && (
                <div className="input-row">
                  <p>Hash :</p>
                  <input
                    type="text"
                    onChange={(e) => this.changeRecharge("Hash", e)}
                  />
                </div>
              )}
              <div className="btn-row">
                <div className="cancel" onClick={this.clickRechargeHidden}>
                  cancel
                </div>
                {UserID === 2 && (
                  <div className="btn" onClick={this.withDrawBtn}>
                    submit
                  </div>
                )}
                {UserID === 3 && (
                  <div className="btn" onClick={this.recharge}>
                    submit
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* 内容 */}
        <div className="content-body">
          <div className="home-contain">
            <div className="card-row">
              <div className="card-col">
                {UserID === 3 && (
                  <div className="card-body1">
                    <div className="after"></div>
                    <div className="icon">
                      <TotalIcon size={32} className="icon-suc" />
                    </div>
                    <div className="txt-box">
                      <h2>{totalNumber}</h2>
                      <p>Today's data total</p>
                    </div>
                  </div>
                )}
                {UserID === 2 && (
                  <div className="card-body1">
                    <div className="after"></div>
                    <div className="icon">
                      <TotalIcon size={32} className="icon-suc" />
                    </div>
                    <div className="txt-box">
                      <h2>{currentlyTotalNum}</h2>
                      <p>Currently incomplete</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="card-col">
                {UserID === 3 && (
                  <div className="card-body2">
                    <div className="after"></div>
                    <div className="icon">
                      <SuccessIcon size={35} className="icon-suc" />
                    </div>
                    <div className="txt-box">
                      <h2>{todaySuccess}</h2>
                      <p>Today Success</p>
                    </div>
                  </div>
                )}
                {UserID === 2 && (
                  <div className="card-body2">
                    <div className="after"></div>
                    <div className="icon">
                      <SuccessIcon size={35} className="icon-suc" />
                    </div>
                    <div className="txt-box">
                      <h2>{totalSuccessNum}</h2>
                      <p>Total Success</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="card-col">
                <div className="card-body3">
                  <div className="after"></div>
                  <div className="icon">
                    <ErrorIcon size={35} className="icon-suc" />
                  </div>
                  <div className="txt-box">
                    <h2>{yesterdayError}</h2>
                    <p>Yesterday Error</p>
                  </div>
                </div>
              </div>
              <div className="card-col">
                <div className="card-body4">
                  <div className="after"></div>
                  <div className="icon">
                    <TotalIcon size={32} className="icon-suc" />
                  </div>
                  <div className="txt-box">
                    <h2>{yesterdaySuccess}</h2>
                    <p>Yesterday Success</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-row">
              <div className="left-card">
                <div className="card-body">
                  <div className="left">
                    <div className="card-info">
                      <div className="img">
                        <img src={circle} alt="" />
                      </div>
                      <h2 className="card-balance"> {walletMoney}</h2>
                      <p className="bal-txt">Wallet Balance</p>
                      {/* <span className="span-txt">+0,8% than last week</span> */}
                    </div> 
                    <div className="change-btn" onClick={this.ClickChangeBtn}>
                      <ChangeIcon
                        size={20}
                        className="change"
                        style={{ transform: `rotate(${change}deg)` }}
                      />
                      Change
                      <DownAllowIcon size={20} className="down" />
                    </div>
                  </div>
                  <div className="right">
                    <div className="data-box">
                      <p className="card-title">Card's Overview</p>
                      <span className="span-title">Card's Overview</span>
                      <ul className="card-list">
                        {pieOption.series[0].data.map((item, index) => {
                          return (
                            <li key={index}>
                              <span className={`circle l${index + 1}`}></span>{" "}
                              {item.name}
                              <span className="value">{item.value}%</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="chart">
                      <ReactECharts
                        echarts={echarts}
                        option={this.getPieOption()}
                        noMerge={true}
                        lazyUpdate={true}
                        theme={"theme_name"}
                        onChartReady={this.onChartReadyCallback}
                        className="pie-chart"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/*每日成功/每周*/}
              <div className="right-card">
                <div className="box">
                  <div className="table">
                    <div className="title">
                      <div className="text-box">
                        <h3>Number Recode</h3>
                        <div className="txt">Number Recode</div>
                      </div>
                      <ul className="tab">
                        <li
                          className={tabName === "Weekly" ? "active" : ""}
                          onClick={() => this.tabChange("Weekly")}
                        >
                          Weekly
                        </li>
                        <li
                          className={tabName === "Monthly" ? "active" : ""}
                          onClick={() => this.tabChange("Monthly")}
                        >
                          Monthly
                        </li>
                      </ul>
                    </div>
                    <div className="body">
                      <div className="row">
                        <div className="svg success">
                          <DownloadIcon size={63} />
                        </div>
                        <div className="title-p">Success</div>{" "}
                        <p className="suc">{SuccessNum}</p>
                      </div>
                      <div className="row">
                        <div className="svg error">
                          <UploadIcon size={63} />
                        </div>
                        <div className="title-p">Fail</div>{" "}
                        <p className="err">{ErrorNum}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="third-row">
              <div className="left">
                <div className="col">
                  <div className="card-header">
                    <div className="title">
                      <h4>Quick Recharge</h4>
                      <span>Quick Recharge</span>
                    </div>
                    <BagIcon size={40} color="#91cc75" className="icon" />
                  </div>
                  <div className="card-body">
                    <div className="card">
                      <div className="actor">
                        <img src={avatar} alt="" />
                      </div>
                      <div className="txt">
                        <h4>{username}</h4>
                        <span>@{username}</span>
                      </div>
                      <div className="i">
                        <ChengIcon size={50} color="#5bcfc5" />
                      </div>
                    </div>
                    <div className="slider-box">
                      <h5>Insert Amount</h5>
                      <h4>{sliderValue}.00 </h4>
                      <Slider
                        // aria-labelledby="continuous-slider"
                        defaultValue={20}
                        value={sliderValue}
                        onChange={this.sliderHandleChange}
                      />
                    </div>
                    <div className="your-balance">
                      <div className="txt">Your Balance</div>
                      <div className="txt"> {walletMoney}</div>
                    </div>
                    <div className="btn">
                      {UserID === 1 && (
                        <div
                          className="btn-box"
                          onClick={this.clickRechargeShow}
                        >
                          WITHDRAW NOW
                        </div>
                      )}
                      {UserID === 2 && (
                        <div
                          className="btn-box"
                          onClick={this.clickRechargeShow}
                        >
                          WITHDRAW NOW
                        </div>
                      )}
                      {UserID === 3 && (
                        <div
                          className="btn-box"
                          onClick={this.clickRechargeShow}
                        >
                          RECHARGE NOW
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="col">
                  <div className="card-header">
                    <div className="title">
                      <h4>Country Record</h4>
                      <span>Country Record</span>
                    </div>
                    <RecodeIcon size={36} color="#709fba" className="icon" />
                  </div>
                  <div className="card-body">
                    {CountryData.length > 0 ? (
                      <ul className="recode">
                        {CountryData.map((item, index) => {
                          return (
                            <div className="li" key={index}>
                              <div className="country-flag">
                                <img
                                  src={`./static/media/${item.country}.png`}
                                  alt=""
                                />
                              </div>
                              <div className="num">+{item.quantity}</div>
                            </div>
                          );
                        })}
                      </ul>
                    ) : (
                      <div className="non-recode">
                        <div className="non">
                          <NoContent size={200} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="middle">
                <div className="col">
                  <div className="card-header">
                    <div className="title">
                      <h4>Balance Change Record</h4>
                      <span>Balance Change Record</span>
                    </div>
                    <RecodeIcon size={36} color="#709fba" className="icon" />
                  </div>
                  <div className="card-body">
                    {MoneyData.length > 0 ? (
                      <ul className="recode">
                        {MoneyData.map((item, index) => {
                          return (
                            <li className="recode-li" key={index || 0}>
                              <div className="time">{item.memo}</div>
                              <div className="amount">$ {item.money}</div>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <div className="non-recode">
                        <div className="non">
                          <NoContent size={200} />
                        </div>
                      </div>
                    )}

                    {/* <div className="recode-btn">
                      <div className="recode-btn-box">View More</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="fourth-row">
              <div className="col-left">
                <div className="table">
                  <div className="table-header">
                    <div className="title">
                      <h3>Previous Transactions</h3>
                      <span className="txt">Previous Transactions</span>
                    </div>
                    {/* <ul className="tab">
                      <li
                        className={tabName === "Monthly" ? "active" : ""}
                        onClick={() => this.tabChange("Monthly")}
                      >
                        Monthly
                      </li>
                      <li
                        className={tabName === "Weekly" ? "active" : ""}
                        onClick={() => this.tabChange("Weekly")}
                      >
                        Weekly
                      </li>
                    </ul> */}
                  </div>
                  <ul className="table-body">
                    <div className="li-body">
                      {MoneyData.map((item, index) => {
                        return (
                          <li className="table-li" key={index}>
                            <div className="svg success">
                              <DownloadIcon size={63} />
                            </div>
                            <div className="name">
                              <h6>{item.memo}</h6>
                              {/* <span>Cashback</span> */}
                            </div>
                            <div className="time">
                              <h6>{item.createtime}</h6>
                            </div>
                            <div className="price">$ {item.money}</div>
                            {/* <div className="status complete">Success</div> */}
                          </li>
                        );
                      })}
                    </div>
                  </ul>
                </div>
              </div>
              <div className="col-right">
                <div className="table">
                  <div className="overview">
                    Income Overview
                    <p>Income Overview </p>
                  </div>
                  <div className="charge-chart">
                    <ReactECharts
                      echarts={echarts}
                      // 强制刷新
                      key={JSON.stringify(this.state.DataOption)}
                      option={this.state.DataOption}
                      theme={"theme_name"}
                      className="chart"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="fifth-row">
              <div className="col">
                <div className="table">
                  <div className="overview">
                    Daily Date
                    <p> Daily Date</p>
                  </div>
                  <div className="charge-chart">
                    <ReactECharts
                      echarts={echarts}
                      // 强制刷新
                      key={JSON.stringify(this.state.DailyDate)}
                      option={this.state.DailyDate}
                      theme={"theme_name"}
                      className="chart"
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="table">
                  <div className="overview">
                    Daily Success Rate
                    <p> Daily Success Rate</p>
                  </div>
                  <div className="charge-chart">
                    <ReactECharts
                      echarts={echarts}
                      // 强制刷新
                      key={JSON.stringify(this.state.DailySuccessRate)}
                      option={this.state.DailySuccessRate}
                      theme={"theme_name"}
                      className="chart"
                    />
                  </div>
                </div>
              </div>
            </div>
            <FooterBar {...this.props} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
